import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import example_1_day_with_week_grid from '../../../../../../../common/src/assets/image/example_1_day_with_week_grid.png';
import template_options_1_day_with_week_grid from '../../../../../../../common/src/assets/image/template_options_1_day_with_week_grid.png';

const Documentation = () => {
  const content = (
    <div id="help">
      <p>
        This template generates a calendar showing 1 day per page with a week
        grid on the right side of the page. Events will be placed within their
        corresponding time slots. Overlapping events will be shown side-by-side.
      </p>
      <p>
        <Image
          alt=""
          src={example_1_day_with_week_grid}
          style={{ width: 913 }}
        />
      </p>
      <h2>Template Settings</h2>
      <p>
        When creating a calendar using the 1 Day/Page with Week Grid template,
        the following options are provided...
      </p>
      <Image
        alt=""
        src={template_options_1_day_with_week_grid}
        style={{ width: 448 }}
      />
      <br />
      <br />
      <table className="table">
        <tbody>
          <tr>
            <td>
              <strong>Start date</strong>
            </td>
            <td>
              The start date can be typed into the box or selected using the
              drop-down arrow. This date will be the left-most column in the
              generated calendar.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Number of days</strong>
            </td>
            <td>
              The total number of days to include in the output. Each day will
              generate to its own worksheet or page.
            </td>
          </tr>
          <tr>
            <td>
              <strong>First timeslot</strong>
            </td>
            <td>
              The first visible timeslot. Any items that end before the
              specified time won't be shown in the timeslot columns.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Last timeslot</strong>
            </td>
            <td>
              The last visible timeslot. Any items that start after the
              specified time won't be shown in the timeslot columns.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Text behavior</strong>
            </td>
            <td>
              The text behavior of items shown in the all-day area and the week
              grid. Options are <strong>Wrap text</strong>,{' '}
              <strong>Don't wrap text</strong>, and{' '}
              <strong>Shrink text to fit</strong>.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Item font size</strong>
            </td>
            <td>
              Override the default font size associated with each calendar data
              source.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Time format</strong>
            </td>
            <td>
              The time format. Supported formats are <strong>12-hour</strong>{' '}
              (8:00 PM), <strong>24-hour</strong> (20:00),{' '}
              <strong>Shortened</strong> (8pm), <strong>Military </strong>
              (2000), and <strong>Numeric </strong>(20.0). An example time is
              shown for each format in the drop-down list.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show timed multiday items in all-day area</strong>
            </td>
            <td>
              When checked, events or starts that have start and end times
              spanning multiple days will be shown in the all-day area instead
              of the timeslot columns.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show start time </strong>
            </td>
            <td>
              When checked, event or task start times will be shown. The
              ordering of times, title, and location values can be specified in
              the <Link to="/Documentation/Options">PrintableCal options</Link>.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show end time</strong>
            </td>
            <td>When checked, event or task end times will be shown.</td>
          </tr>
          <tr>
            <td>
              <strong>Show location</strong>
            </td>
            <td>When checked, event or task locations will be shown.</td>
          </tr>
          <tr>
            <td>
              <strong>Show location on separate line</strong>
            </td>
            <td>
              When checked, event or task locations will be shown on a separate
              line, below the title and above the description.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show description</strong>
            </td>
            <td>
              When checked, descriptions will be included in event and task
              blocks (if enough space is available).
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show blank line above description</strong>
            </td>
            <td>
              When checked, a blank line will be shown above the description, to
              help differentiate the description from the title and location
              values.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Use bold text for item times</strong>
            </td>
            <td>
              <span>
                When checked, item times will be in bold text, to help
                differentiate from other values.
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Use bold text for item titles</strong>
            </td>
            <td>
              When checked, item titles will be in bold text, to help
              differentiate from other values.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Templates-and-Options/Day-1-Day-Page-with-Week-Grid"
      commentsId="commentsplus_post_1189_1628"
      title="1 Day/Page with Week Grid Template"
      description="Generate a calendar showing 1 day per page with a week grid on the right side of the page. Events will be placed within their corresponding time slots. Overlapping events will be shown side-by-side."
      keywords="day template, week grid"
      content={content}
    />
  );
};

export default Documentation;
